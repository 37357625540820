import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useEntityConfigEditor } from '../hooks/useEntityConfigEditor';
import { EntityConfigModule } from '../types/EntityConfig';
import { ConfigVisualEditorModuleModal } from '../components/ConfigVisualEditorModuleModal';

export function EntitiesEditModuleRoute() {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const navigate = useNavigate();

  const { params, resources, modules } = useEntityConfigEditor();

  const { moduleId } = useParams();

  const handleSubmit = async (module: EntityConfigModule) => {
    if (!moduleId) { return; }
    modules.update(moduleId, module);
  };

  const module = modules.data.find(({ id }) => id === moduleId);

  return (
    <ConfigVisualEditorModuleModal
      title="Add Resource"
      isOpen={isModalOpen}
      module={module}
      onClose={() => setIsModalOpen(false)}
      onCloseFinished={() => navigate(-1)}
      onSubmit={handleSubmit}
      params={params.data}
      resources={resources.data}
    />
  );
}
