import { useState } from 'react';
import {
  FormField,
  NCInputRadioGroup,
  NCInputText,
  NCSelect,
  useForm,
} from '@daupler/nexus-components';
import { ResourceFormProps } from './resource-form-types';
import { useSyncResourceForm } from '../../hooks/useSyncResourceForm';
import { ResourceBaseForm } from './ResourceBaseForm';
import { getBaseResourceFormFields } from '../../utils/resource-forms';
import { EntityConfigRef, EntityConfigResourceType, ScheduleRule } from '../../types/EntityConfig';
import InputRRule from '../InputRRule';
import { InputList } from '../InputList';

export function ScheduleForm({
  onChange,
  onValidate,
  resourceData,
  resources,
  workgroups,
}: ResourceFormProps) {
  const getEmptyRule = () => ({
    display_name: '',
    dtstart: '',
    duration: {
      months: 0,
      weeks: 0,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    },
    rrule: '',
    durationUnit: '',
    durationValue: '',
    _assigned_shift_ref: undefined,
  });
  const [newRule, setNewRule] = useState<ScheduleRule>(getEmptyRule());

  const defaultShiftRef: FormField<EntityConfigRef | undefined> = {
    invalidMessage: '',
    name: 'defaultShiftRef',
    validate: () => true,
    validMessage: '',
    value: undefined,
  };
  const holidayShiftRef: FormField<EntityConfigRef | undefined> = {
    invalidMessage: '',
    name: 'holidayShiftRef',
    validate: () => true,
    validMessage: '',
    value: undefined,
  };
  const rules: FormField<ScheduleRule[]> = {
    invalidMessage: '',
    name: 'rules',
    validate: () => true,
    validMessage: '',
    value: [],
  };

  const {
    formState,
    onChange: onFormChange,
    isFormValid,
  } = useForm({
    ...getBaseResourceFormFields({ resourceData }),
    defaultShiftRef,
    holidayShiftRef,
    rules,
  });

  useSyncResourceForm({
    isFormValid,
    onChange,
    onValidate,
    resourceData: {
      display_name: formState.displayName.value,
      key: formState.key.value,
      _workgroup_ref: formState.workgroupRef.value,
      data: {
        _default_shift_ref: formState.defaultShiftRef.value,
        _holiday_shift_ref: formState.holidayShiftRef.value,
        rules: formState.rules.value,
      },
    },
  });

  const {
    _assigned_shift_ref: newRuleAssignedShiftRef,
  } = newRule;
  const [durationUnit, setDurationUnit] = useState('');
  const [durationValue, setDurationValue] = useState(0);
  const addRule = () => {
    const {
      _assigned_shift_ref: assignedShiftRef,
    } = newRule;
    onFormChange(formState.rules.name, [
      ...formState.rules.value,
      {
        ...newRule,
        display_name: newRule.display_name,
        dtstart: newRule.dtstart,
        rrule: newRule.rrule,
        duration: { [durationUnit]: newRule.duration },
        _assigned_shift_ref: assignedShiftRef,
      },
    ]);
    setNewRule(getEmptyRule());
  };

  const removeRule = (data: ScheduleRule[]) => {
    onFormChange(
      formState.rules.name,
      data,
    );
  };

  return (
    <>
      <ResourceBaseForm
        formState={formState}
        onChange={onFormChange}
        workgroups={workgroups}
      />
      <NCSelect
        label="Default Shift"
        className="nc-l-mt_200_mobile"
        name={formState.defaultShiftRef.name}
        options={[
          { label: 'Choose one...', value: '' },
          ...resources
            .filter(({ resource }) => resource.type === EntityConfigResourceType.SHIFTS)
            .map(({ resource, id }) => ({
              label: resource.display_name,
              value: id,
            })),
        ]}
        onChange={(event) => onFormChange(
          formState.defaultShiftRef.name,
          {
            key: event.target.value,
            type: EntityConfigResourceType.SHIFTS,
          },
        )}
        value={formState.defaultShiftRef.value?.key ?? ''}
        isValid={formState.defaultShiftRef.isValid}
        hint={formState.defaultShiftRef.message}
      />
      <NCSelect
        label="Holiday Shift"
        className="nc-l-mt_200_mobile"
        name={formState.holidayShiftRef.name}
        options={[
          { label: 'Choose one...', value: '' },
          ...resources
            .filter(({ resource }) => resource.type === EntityConfigResourceType.SHIFTS)
            .map(({ resource, id }) => ({
              label: resource.display_name,
              value: id,
            })),
        ]}
        onChange={(event) => onFormChange(
          formState.holidayShiftRef.name,
          {
            key: event.target.value,
            type: EntityConfigResourceType.SHIFTS,
          },
        )}
        value={formState.holidayShiftRef.value?.key ?? ''}
        isValid={formState.holidayShiftRef.isValid}
        hint={formState.holidayShiftRef.message}
      />

      <InputList<ScheduleRule>
        label="Rules"
        data={formState.rules.value}
        renderItem={(rule) => (
          <ul>
            <li>{`Display Name: ${rule.display_name}`}</li>
            <li>{`Start Date: ${rule.dtstart}`}</li>
            <li>{`RRule: ${rule.rrule}`}</li>
            <li>{`Duration: ${JSON.stringify(rule.duration)}`}</li>
            <li>
              {`Assigned Shift: ${resources
                .find((resource) => {
                  if (resource.resource.type !== EntityConfigResourceType.SHIFTS) {
                    return false;
                  }
                  const {
                    _assigned_shift_ref: assignedShiftRef,
                  } = rule;
                  return resource.resource.key === assignedShiftRef?.key;
                })?.resource.display_name}`}
            </li>
          </ul>
        )}
        onAdd={addRule}
        onRemove={(data) => removeRule(data)}
        placeholder="No Rules here yet!"
      >
        <>
          <NCSelect
            label="Assigned Shift"
            name="rule-assigned_shift"
            options={[
              { label: 'Choose one...', value: '' },
              ...resources
                .filter(({ resource }) => resource.type === EntityConfigResourceType.SHIFTS)
                .map(({ resource, id }) => ({
                  label: resource.display_name,
                  value: id,
                })),
            ]}
            onChange={(event) => {
              setNewRule((state) => ({
                ...state,
                _assigned_shift_ref: {
                  key: event.target.value,
                  type: EntityConfigResourceType.SHIFTS,
                },
              }));
            }}
            value={newRuleAssignedShiftRef?.key ?? ''}
          />
          <NCInputText
            label="Display Name"
            className="nc-l-mt_200_mobile"
            name="rule-display_name"
            onChange={(event) => {
              setNewRule((state) => ({ ...state, display_name: event.target.value }));
            }}
            value={newRule.display_name}
          />
          <InputRRule
            className="nc-l-mt_200_mobile"
            onChange={(value) => {
              setNewRule((state) => ({
                ...state,
                dtstart: value.dtstart,
                rrule: value.rrule,
              }));
            }}
            rrule={newRule.rrule}
            dtstart={newRule.dtstart}
          />
          <NCInputRadioGroup
            name="rule-duration_unit"
            className="nc-l-mt_200_mobile"
            label="Duration Unit"
            onChange={(event) => {
              setDurationUnit(event.target.value);
            }}
            options={[
              {
                label: 'Months',
                id: 'months',
              },
              {
                label: 'Weeks',
                id: 'weeks',
              },
              {
                label: 'Days',
                id: 'days',
              },
              {
                label: 'Hours',
                id: 'hours',
              },
              {
                label: 'Minutes',
                id: 'minutes',
              },
              {
                label: 'Seconds',
                id: 'seconds',
              },
            ]}
            value={durationUnit}
          />
          <NCInputText
            name="rule-duration_value"
            label="Duration Value"
            onChange={(event) => {
              setDurationValue(parseInt(event.target.value, 10));
            }}
            step={1}
            type="number"
            value={durationValue}
          />
        </>
      </InputList>
    </>
  );
}
