import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useParams } from 'react-router';
import clsx from 'clsx';
import {
  NCButton,
  NCInputCheckbox,
  NCWell,
} from '@daupler/nexus-components';
import { useAuthentication } from '../../hooks/useAuthentication';
import { EntityDetail } from '../../types/Entity';
import { DauplerApi } from '../../lib/daupler-api';
import { config as appConfig } from '../../config';
import { logger } from '../../utils/logger';
import {
  EntityConfigResourceActionType,
  EntityConfigPlanResponse,
  EntityConfigResourceAction,
  EntityConfigModuleActionType,
  EntityConfigModuleAction,
} from '../../types/EntityConfig';
import { ConfigAction } from './ConfigAction';
import { ConfigActions } from './ConfigActions';
import { ConfigVisualEditor } from '../../components/ConfigVisualEditor';
import { ConfigVisualEditorHeader } from '../../components/ConfigVisualEditorHeader';
import './NewView.css';
import { ConfigVisualEditorObjectsMenu } from '../../components/ConfigVisualEditorObjectsMenu';
import { EditorMode } from '../../types/ConfigEditor';
import { EditorEntityParam, useEntityConfigEditor } from '../../hooks/useEntityConfigEditor';

type EntitiesIdRouteNewProps = {
  entity: EntityDetail | null;
};

export function EntitiesIdRouteNew({
  entity,
}: EntitiesIdRouteNewProps) {
  const { id } = useParams();
  const { authToken } = useAuthentication();
  const dauplerApi = useRef(new DauplerApi({
    baseUrl: appConfig.dauplerApiBaseUrl,
    fetch: fetch.bind(window),
  }));

  const [planResult, setPlanResult] = useState<EntityConfigPlanResponse | null>(null);

  const [isWorking, setIsWorking] = useState(false);
  const [serverError, setServerError] = useState('');
  const apply = async () => {
    setServerError('');
    if (!authToken) { return; }
    if (!id) { return; }
    try {
      setIsWorking(true);
      await dauplerApi.current.applyEntityConfig(
        authToken,
        id,
        '',
        '',
      );
    } catch (err) {
      logger.error('Failed to apply configuration', err);
      if (err instanceof Error) {
        setServerError(err.message);
      }
    } finally {
      setIsWorking(false);
    }
  };

  const modulesToEnable = (planResult?.meta.plan.module_actions ?? []).filter(
    (moduleAction) => moduleAction.action_type === EntityConfigModuleActionType.ENABLE,
  );
  const modulesToUpdate = (planResult?.meta.plan.module_actions ?? []).filter(
    (moduleAction) => moduleAction.action_type === EntityConfigModuleActionType.UPDATE,
  );
  const modulesToDisable = (planResult?.meta.plan.module_actions ?? []).filter(
    (moduleAction) => moduleAction.action_type === EntityConfigModuleActionType.DISABLE,
  );

  const resourcesToCreate = (planResult?.meta.plan.resource_actions ?? []).filter(
    (resourceAction) => resourceAction.action_type === EntityConfigResourceActionType.CREATE,
  );
  const resourcesToUpdate = (planResult?.meta.plan.resource_actions ?? []).filter(
    (resourceAction) => resourceAction.action_type === EntityConfigResourceActionType.UPDATE,
  );
  const resourcesToDelete = (planResult?.meta.plan.resource_actions ?? []).filter(
    (resourceAction) => resourceAction.action_type === EntityConfigResourceActionType.DELETE,
  );

  const [editorMode, setEditorMode] = useState<EditorMode>(EditorMode.VISUAL);
  const [searchFilter, setSearchFilter] = useState('');
  const [serachParams, setSearchParams] = useSearchParams();
  const [isTrayOpen, setIsTrayOpen] = useState(serachParams.get('objectsMenu') === 'true');
  useEffect(() => {
    if (serachParams.get('objectsMenu') === 'true' && !isTrayOpen) {
      setIsTrayOpen(true);
    } else if (serachParams.get('objectsMenu') === 'false' && isTrayOpen) {
      setIsTrayOpen(false);
    }
  }, [isTrayOpen, serachParams]);

  const {
    keys,
    modules,
    params,
    resources,
  } = useEntityConfigEditor();

  const keysAndUsages: [string, number][] = keys.data
    .map((key) => [
      key,
      params.data.filter((param) => param.param.key === key).length,
    ]);

  const paramsAndUsages: [EditorEntityParam, number][] = params.data
    .map((param) => [
      param,
      [
        ...modules.data.filter(({ module }) => JSON.stringify(module).includes(param.param.key)),
        // ...config
        //   .resources.filter((resource) => JSON.stringify(resource).includes(param.key)),
      ].length,
    ]);

  return (
    <div className={clsx('entities_id_route', { 'entities_id_route--tray': isTrayOpen })}>
      <div className="entities_id_route__header">
        <ConfigVisualEditorHeader
          entity={entity}
          editorMode={editorMode}
          onEditorModeChange={setEditorMode}
          onSearchFilterUpdate={setSearchFilter}
          searchFilter={searchFilter}
          isObjectsMenuOpen={isTrayOpen}
          onObjectsMenuClick={() => {
            const searchParams = new URLSearchParams(serachParams.toString());
            searchParams.set('objectsMenu', (!isTrayOpen).toString());
            setSearchParams(searchParams);
          }}
        />
      </div>

      <div className="entities_id_route__workspace">
        {serverError ? (
          <p className="nc-t-center nc-t-body_regular_mobile nc-t-error nc-l-mt_200_mobile">{serverError}</p>
        ) : null}

        {planResult ? (
          <>
            <NCWell>
              <ConfigActions<EntityConfigModuleAction>
                actionType={EntityConfigModuleActionType.ENABLE}
                renderAction={(moduleAction) => (
                  <ConfigAction
                    itemKey={moduleAction.module_type}
                    details={JSON.stringify(moduleAction)}
                    actionType={EntityConfigModuleActionType.ENABLE}
                  />
                )}
                actions={modulesToEnable}
                title="Enable the following modules:"
              />
              <ConfigActions<EntityConfigModuleAction>
                actionType={EntityConfigModuleActionType.UPDATE}
                renderAction={(moduleAction) => (
                  <ConfigAction
                    itemKey={moduleAction.module_type}
                    details={JSON.stringify(moduleAction)}
                    actionType={EntityConfigModuleActionType.UPDATE}
                  />
                )}
                actions={modulesToUpdate}
                title="Update the following modules:"
              />
              <ConfigActions<EntityConfigModuleAction>
                actionType={EntityConfigModuleActionType.DISABLE}
                renderAction={(moduleAction) => (
                  <ConfigAction
                    itemKey={moduleAction.module_type}
                    details={JSON.stringify(moduleAction)}
                    actionType={EntityConfigModuleActionType.DISABLE}
                  />
                )}
                actions={modulesToDisable}
                title="Disable the following modules:"
              />

              <ConfigActions<EntityConfigResourceAction>
                actionType={EntityConfigResourceActionType.CREATE}
                renderAction={(resourceAction: EntityConfigResourceAction) => (
                  <ConfigAction
                    itemKey={resourceAction.key}
                    type={resourceAction.resource_type}
                    displayName={resourceAction.planned_resource?.display_name ?? ''}
                    details={JSON.stringify(resourceAction)}
                    actionType={EntityConfigResourceActionType.CREATE}
                  />
                )}
                actions={resourcesToCreate}
                title="Create the following resources:"
              />
              <ConfigActions<EntityConfigResourceAction>
                actionType={EntityConfigResourceActionType.UPDATE}
                renderAction={(resourceAction) => (
                  <ConfigAction
                    itemKey={resourceAction.key}
                    type={resourceAction.resource_type}
                    displayName={resourceAction.planned_resource?.display_name ?? ''}
                    details={JSON.stringify(resourceAction)}
                    actionType={EntityConfigResourceActionType.UPDATE}
                  />
                )}
                actions={resourcesToUpdate}
                title="Update the following resources:"
              />
              <ConfigActions<EntityConfigResourceAction>
                actionType={EntityConfigResourceActionType.DELETE}
                renderAction={(resourceAction) => (
                  <ConfigAction
                    itemKey={resourceAction.key}
                    type={resourceAction.resource_type}
                    displayName={resourceAction.planned_resource?.display_name ?? ''}
                    details={JSON.stringify(resourceAction, undefined, 2)}
                    actionType={EntityConfigResourceActionType.DELETE}
                  />
                )}
                actions={resourcesToDelete}
                title="Delete the following resources:"
              />
            </NCWell>
            <div className="nc-flex nc-flex--justify_between nc-flex--align_end">
              <p className="nc-t-sub_text_light nc-t-grey_700 nc-l-mt_utilities_50_mobile">
                Plan hash: 123
              </p>
              <NCButton
                appearance={NCButton.appearances.LINK}
                color={NCButton.colors.PRIMARY}
                width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
                type="submit"
                onClick={() => {
                  setPlanResult(null);
                }}
                disabled={isWorking}
              >
                Edit
              </NCButton>
            </div>
            <div className="nc-flex nc-l-mt_200_mobile">
              <NCButton
                color={NCButton.colors.PRIMARY}
                appearance={NCButton.appearances.INVERSE}
                width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
                type="submit"
                onClick={apply}
                disabled={isWorking}
                className="nc-l-mr_200_mobile"
              >
                {isWorking ? 'Applying...' : 'Apply'}
              </NCButton>
              <NCInputCheckbox
                label="Dry Run"
                name="dryrun"
                checked
              />
            </div>
          </>
        ) : (
          <ConfigVisualEditor
            configurationObject={{
              modules: modules.data.map(({ module }) => module),
              params: params.data.map(({ param }) => param),
              resources: resources.data.map(({ resource }) => resource),
            }}
            editorMode={editorMode}
            searchFilter={searchFilter}
            resources={resources.data}
            modules={modules.data}
          />
        )}
      </div>

      {isTrayOpen ? (
        <div className="entities_id_route__tray">
          <ConfigVisualEditorObjectsMenu
            keys={keysAndUsages}
            onKeyAdd={keys.add}
            onKeyFilter={(key) => setSearchFilter(key)}
            onKeyRemove={keys.remove}
            onParamAdd={params.add}
            onParamFilter={(paramKey) => setSearchFilter(paramKey)}
            onParamRemove={params.remove}
            onParamUpdate={params.update}
            params={paramsAndUsages}
          />
        </div>
      ) : null}
    </div>
  );
}
