import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { NCInputText } from '@daupler/nexus-components';
import { ConfigVisualEditorModule } from './ConfigVisualEditorModule';
import { ConfigVisualEditorResource } from './ConfigVisualEditorResource';
import './ConfigVisualEditor.css';
import { EditorMode } from '../types/ConfigEditor';
import { EntityConfig } from '../types/EntityConfig';
import { EditorEntityModule, EditorEntityResource } from '../hooks/useEntityConfigEditor';

type ConfigVisualEditorProps = {
  configurationObject: EntityConfig | null;
  editorMode: EditorMode;
  modules: EditorEntityModule[];
  resources: EditorEntityResource[];
  searchFilter: string;
};

export function ConfigVisualEditor({
  configurationObject,
  editorMode,
  resources,
  modules,
  searchFilter,
}: ConfigVisualEditorProps) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  if (editorMode === EditorMode.YAML) {
    return (
      <NCInputText
        multiline
        rows={3}
        name="configuration-raw"
        hint=""
        value={JSON.stringify(configurationObject)}
        className="config_visual_editor__code"
        onChange={() => {}}
        label="Configuration Raw"
        labelHidden
      />
    );
  }

  return (
    <div className="config_visual_editor__workspace">
      {modules.length ? modules
        .filter((module) => (searchFilter
          ? JSON.stringify(module).toLowerCase().includes(searchFilter.toLowerCase())
          : true))
        .map(({ module }) => (
          <div key={module.module_type} className="config_visual_editor__node">
            <ConfigVisualEditorModule
              moduleType={module.module_type}
              data={module.data}
            />
          </div>
        )) : null}
      {resources.length
        ? resources
          .filter(({ resource }) => (searchFilter
            ? JSON.stringify(resource).toLowerCase().includes(searchFilter.toLowerCase())
            : true))
          .map(({ resource, id }) => (
            <div key={`${resource.type}-${resource.key}`} className="config_visual_editor__node">
              <ConfigVisualEditorResource
                type={resource.type}
                resourceKey={resource.key}
                displayName={resource.display_name}
                onEdit={() => navigate(`resources/${id}?${searchParams.toString()}`)}
              />
            </div>
          ))
        : null}
    </div>
  );
}
