import { NCSelect } from '@daupler/nexus-components';
import {
  EntityConfigResourceDispatchActionDispatchActionRunPolicyParams,
  EntityConfigResourceType,
} from '../../types/EntityConfig';
import { DispatchActionProps } from './resource-form-types';

export function DispatchActionFormRunPolicy({
  onChange,
  value,
  resources,
}: DispatchActionProps) {
  const {
    _policy_id_ref: policyIdRef,
  } = value as EntityConfigResourceDispatchActionDispatchActionRunPolicyParams;
  return (
    <NCSelect
      label="Dispatch Policy"
      name="multi_ack_transitional-dispatch_policy"
      options={[
        { value: '', label: 'Choose one...' },
        ...resources
          .filter(({ resource }) => resource.type === EntityConfigResourceType.DISPATCH_POLICIES)
          .map(({ id, resource }) => ({
            label: resource.display_name,
            value: id,
          })),
      ]}
      value={policyIdRef?.key ?? ''}
      onChange={(event) => onChange({
        ...value as EntityConfigResourceDispatchActionDispatchActionRunPolicyParams,
        _policy_id_ref: {
          key: event.target.value,
          type: EntityConfigResourceType.DISPATCH_POLICIES,
        },
      } as EntityConfigResourceDispatchActionDispatchActionRunPolicyParams)}
    />
  );
}
