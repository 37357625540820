import { NCInputCheckbox } from '@daupler/nexus-components';
import { DispatchActionProps } from './resource-form-types';
import { EntityConfigResourceDispatchActionDispatchActionCloseParams } from '../../types/EntityConfig';

export function DispatchActionFormActionClose({
  onChange,
  value,
}: DispatchActionProps) {
  const {
    complete,
    archive,
  } = value as EntityConfigResourceDispatchActionDispatchActionCloseParams;
  return (
    <>
      <NCInputCheckbox
        label="Complete"
        name="dispatch_action_close-complete"
        onChange={() => onChange({
          ...value as EntityConfigResourceDispatchActionDispatchActionCloseParams,
          complete: !complete,
        })}
        checked={complete}
      />
      <NCInputCheckbox
        label="Archive"
        name="dispatch_action_close-archive"
        className="nc-l-mt_200_mobile"
        onChange={() => onChange({
          ...value as EntityConfigResourceDispatchActionDispatchActionCloseParams,
          archive: !archive,
        })}
        checked={archive}
      />
    </>
  );
}
