import { useState } from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { NCButton, NCContextMenu, NCInputText } from '@daupler/nexus-components';
import { EntityDetail } from '../types/Entity';
import './ConfigVisualEditorHeader.css';
import { NavToolPicker } from './NavToolPicker';
import { EditorMode } from '../types/ConfigEditor';

type ConfigVisualEditorHeaderProps = {
  entity: EntityDetail | null;
  editorMode: EditorMode;
  isObjectsMenuOpen: boolean;
  onEditorModeChange: (editorMode: EditorMode) => void;
  onObjectsMenuClick: () => void;
  onSearchFilterUpdate: (value: string) => void;
  searchFilter: string;
};

export function ConfigVisualEditorHeader({
  editorMode,
  entity,
  isObjectsMenuOpen,
  searchFilter,
  onEditorModeChange,
  onObjectsMenuClick,
  onSearchFilterUpdate,
}: ConfigVisualEditorHeaderProps) {
  const [isModuleMenuOpen, setIsModuleMenuOpen] = useState(false);
  const [showModules, setShowModules] = useState(true);
  const [isResourceMenuOpen, setIsResourceMenuOpen] = useState(false);
  const [showResources, setShowResources] = useState(true);

  const [isSearching, setIsSearching] = useState(false);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  return (
    <div className="config_visual_editor_header">
      <div className="nc-flex nc-flex--align_center nc-flex--justify_between nc-flex--gap_1 config_visual_editor__title_row">
        <div className="nc-flex nc-flex--align_center nc-flex--gap_1">
          <NavToolPicker />
          <div className={clsx('config_visual_editor_header__title', { 'config_visual_editor_header__title--hidden': isSearching })}>
            <span className="nc-t-sub_text_light_mobile nc-t-h3_light_tablet">{entity?.name}</span>
            {' '}
            <span className="nc-t-info_text_medium_mobile nc-t-h5_medium_tablet">{`(${entity?.shortName})`}</span>
          </div>
        </div>

        <div
          className={clsx('nc-flex nc-flex--align_end nc-flex--gap_1', {
            'config_visual_editor_header__search_grp--wide': isSearching,
          })}
        >
          <NCButton
            className="config_visual_editor_header__search_btn"
            color={NCButton.colors.GREY}
            width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
            onClick={() => setIsSearching(!isSearching)}
          >
            {isSearching ? (
              <i className="fa-solid fa-times fa-fw" />
            ) : (
              <i className="fa-solid fa-search fa-fw" />
            )}
          </NCButton>
          <div
            className={clsx('config_visual_editor_header__search_field', {
              'config_visual_editor_header__search_field--searching': isSearching,
              'nc-flex_item--1': isSearching,
            })}
          >
            <NCInputText
              name="search"
              label="Search"
              labelHidden
              value={searchFilter}
              onChange={(event) => onSearchFilterUpdate(event.target.value)}
              tag={(<i className="fa-solid fa-search" />)}
              button={searchFilter ? {
                label: (<i className="fa-solid fa-fw fa-circle-x nc-t-grey_500" />),
                onClick: () => { onSearchFilterUpdate(''); },
              } : undefined}
            />
          </div>
          <NCButton
            appearance={NCButton.appearances.SOLID}
            className={clsx({ 'config_visual_editor_header__view_toggle--hidden': isSearching })}
            color={NCButton.colors.GREY}
            size={[[NCButton.breakpoints.MOBILE, NCButton.sizes.DEFAULT]]}
            width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
            onClick={() => onEditorModeChange(editorMode === EditorMode.VISUAL
              ? EditorMode.YAML : EditorMode.VISUAL)}
          >
            {editorMode === EditorMode.VISUAL ? (<i className="fa-solid fa-fw fa-code" />) : (<i className="fa-solid fa-fw fa-window" />)}
          </NCButton>
        </div>
      </div>

      <div className="nc-flex nc-flex--align_end nc-flex--justify_between config_visual_editor__tool_row">
        <div className="nc-flex nc-flex--align_end nc-flex--gap_1">
          <NCContextMenu
            id="modules"
            isOpen={isModuleMenuOpen}
            options={[[
              {
                id: 'modules-1',
                label: 'Add Module',
                labelText: 'Add Module',
                onClick: () => {
                  navigate(`add-module?${searchParams.toString()}`);
                },
                type: NCContextMenu.itemTypes.BUTTON,
              },
              {
                id: 'modules-2',
                label: showModules ? 'Hide Modules' : 'Show Modules',
                labelText: showModules ? 'Hide Modules' : 'Show Modules',
                onClick: () => { setShowModules(!showModules); },
                type: NCContextMenu.itemTypes.BUTTON,
              },
            ]]}
            setIsOpen={setIsModuleMenuOpen}
            className="config_visual_editor_header__toolbar__menu"
            classNameOnOpen="config_visual_editor_header__toolbar__menu--open"
            flyoverClassName="config_visual_editor_header__toolbar__menu_dropdown"
          >
            <span className="config_visual_editor_header__toolbar__menu__inner">Modules</span>
          </NCContextMenu>
          <NCContextMenu
            id="modules"
            isOpen={isResourceMenuOpen}
            options={[[
              {
                id: 'resources-1',
                label: 'Add Resource',
                labelText: 'Add Resource',
                onClick: () => {
                  navigate(`add-resource?${searchParams.toString()}`);
                },
                type: NCContextMenu.itemTypes.BUTTON,
              },
              {
                id: 'resources-2',
                label: showResources ? 'Hide Resources' : 'Show Resources',
                labelText: showResources ? 'Hide Resources' : 'Show Resources',
                onClick: () => { setShowResources(!showResources); },
                type: NCContextMenu.itemTypes.BUTTON,
              },
            ]]}
            setIsOpen={setIsResourceMenuOpen}
            className="config_visual_editor_header__toolbar__menu"
            classNameOnOpen="config_visual_editor_header__toolbar__menu--open"
            flyoverClassName="config_visual_editor_header__toolbar__menu_dropdown"
          >
            <span className="config_visual_editor_header__toolbar__menu__inner">Resources</span>
          </NCContextMenu>
        </div>

        <NCButton
          appearance={NCButton.appearances.OUTLINE}
          size={[[NCButton.breakpoints.MOBILE, NCButton.sizes.XS]]}
          width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
          color={NCButton.colors.DARK}
          onClick={onObjectsMenuClick}
          active={isObjectsMenuOpen}
        >
          Objects
        </NCButton>
      </div>
    </div>
  );
}
