import { FormField, NCSelect, useForm } from '@daupler/nexus-components';
import { ResourceFormProps } from './resource-form-types';
import { useSyncResourceForm } from '../../hooks/useSyncResourceForm';
import { ResourceBaseForm } from './ResourceBaseForm';
import { getBaseResourceFormFields } from '../../utils/resource-forms';
import { EntityConfigRef, EntityConfigResourceIncidentTransfer, EntityConfigResourceType } from '../../types/EntityConfig';

export function IncidentTransferForm({
  onChange,
  onValidate,
  resourceData,
  resources,
  workgroups,
}: ResourceFormProps) {
  const {
    data: {
      _category_ref: categoryRefValue,
      _dispatch_policy_ref: dispatchPolicyRefValue,
    },
  } = resourceData as EntityConfigResourceIncidentTransfer ?? { data: {} };
  const dispatchPolicyRef: FormField<EntityConfigRef | undefined> = {
    name: 'dispatchPolicyRef',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: dispatchPolicyRefValue ?? undefined,
  };
  const categoryRef: FormField<EntityConfigRef | undefined> = {
    name: 'categoryRef',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: categoryRefValue ?? undefined,
  };
  const {
    formState,
    onChange: onFormChange,
    isFormValid,
  } = useForm({
    ...getBaseResourceFormFields({ resourceData }),
    dispatchPolicyRef,
    categoryRef,
  });

  useSyncResourceForm({
    isFormValid,
    onChange,
    onValidate,
    resourceData: {
      display_name: formState.displayName.value,
      key: formState.key.value,
      _workgroup_ref: formState.workgroupRef.value,
      data: {
        _dispatch_policy_ref: formState.dispatchPolicyRef.value,
        _category_ref: formState.categoryRef.value,
      },
    },
  });

  return (
    <>
      <ResourceBaseForm
        formState={formState}
        onChange={onFormChange}
        workgroups={workgroups}
      />
      <NCSelect
        label="Dispatch Policy"
        className="nc-l-mt_200_mobile"
        name={formState.dispatchPolicyRef.name}
        options={[
          { label: 'Choose one...', value: '' },
          ...resources
            .filter(({ resource }) => resource.type === EntityConfigResourceType.DISPATCH_POLICIES)
            .map(({ resource, id }) => ({
              label: resource.display_name,
              value: id,
            })),
        ]}
        onChange={(event) => onFormChange(
          formState.dispatchPolicyRef.name,
          {
            key: event.target.value,
            type: EntityConfigResourceType.DISPATCH_POLICIES,
          },
        )}
        value={formState.dispatchPolicyRef.value?.key ?? ''}
      />
      <NCSelect
        label="Service Category"
        className="nc-l-mt_200_mobile"
        name={formState.categoryRef.name}
        options={[
          { label: 'Choose one...', value: '' },
          ...resources
            .filter(({ resource }) => resource.type === EntityConfigResourceType.SERVICE_CATEGORIES)
            .map(({ resource, id }) => ({
              label: resource.display_name,
              value: id,
            })),
        ]}
        onChange={(event) => onFormChange(
          formState.categoryRef.name,
          {
            key: event.target.value,
            type: EntityConfigResourceType.SERVICE_CATEGORIES,
          },
        )}
        value={formState.categoryRef.value?.key ?? ''}
      />
    </>
  );
}
