import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ConfigVisualEditorResourceModal } from '../components/ConfigVisualEditorResourceModal';
import { useEntityConfigEditor } from '../hooks/useEntityConfigEditor';
import { EntityConfigResource } from '../types/EntityConfig';

export function EntitiesEditResourceRoute() {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const navigate = useNavigate();

  const { params, resources } = useEntityConfigEditor();

  const { resourceId } = useParams();
  const handleSubmit = async (resource: EntityConfigResource) => {
    if (!resourceId) { return; }
    resources.update(resourceId, resource);
  };

  return (
    <ConfigVisualEditorResourceModal
      title="Edit Resource"
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      onCloseFinished={() => navigate(-1)}
      onSubmit={handleSubmit}
      params={params.data}
      resource={resources.data.find(({ id }) => id === resourceId)}
      resources={resources.data}
    />
  );
}
