import { EntityConfigModuleType } from '../types/EntityConfig';
import { DaytimeDispatchForm } from './module-forms/DaytimeDispatchForm';
import { EngageForm } from './module-forms/EngageForm';
import { ModuleFormProps } from './module-forms/module-form-types';
import { NotifyForm } from './module-forms/NotifyForm';

type ConfigVisualEditorModuleFormsProps = {
  moduleType: EntityConfigModuleType;
} & ModuleFormProps;

export function ConfigVisualEditorModuleForms({
  module,
  moduleType,
  onChange,
  onValidate,
  params,
  resources,
  workgroups,
}: ConfigVisualEditorModuleFormsProps) {
  switch (moduleType) {
    case EntityConfigModuleType.DAYTIME_DISPATCH:
      return (
        <DaytimeDispatchForm
          module={module}
          onChange={onChange}
          onValidate={onValidate}
          params={params}
          resources={resources}
          workgroups={workgroups}
        />
      );
    case EntityConfigModuleType.ENGAGE:
      return (
        <EngageForm
          module={module}
          onChange={onChange}
          onValidate={onValidate}
          params={params}
          resources={resources}
          workgroups={workgroups}
        />
      );
    case EntityConfigModuleType.NOTIFY:
      return (
        <NotifyForm
          module={module}
          onChange={onChange}
          onValidate={onValidate}
          params={params}
          resources={resources}
          workgroups={workgroups}
        />
      );
    default:
      return <p>Unknown module type provided</p>;
  }
}
