import { createContext, useContext, useState } from 'react';
import { v4 } from 'uuid';
import {
  EntityConfig,
  EntityConfigModule,
  EntityConfigParam,
  EntityConfigResource,
} from '../types/EntityConfig';

export type EditorEntityModule = {
  module: EntityConfigModule;
  id: string;
};

export type EditorEntityParam = {
  param: EntityConfigParam;
  id: string;
};

export type EditorEntityResource = {
  resource: EntityConfigResource;
  id: string;
};

export type EditorEntityConfig = {
  keys: string[];
  modules: EditorEntityModule[];
  params: EditorEntityParam[];
  resources: EditorEntityResource[];
};

export type EntityConfigEditorStore = {
  keys: {
    data: string[],
    add: (key: string) => void,
    remove: (key: string) => void,
  },
  modules: {
    data: EditorEntityModule[],
    add: (module: EntityConfigModule) => void,
    update: (id: string, module: EntityConfigModule) => void,
    remove: (id: string) => void,
  },
  params: {
    data: EditorEntityParam[],
    add: (module: EntityConfigParam) => void,
    update: (id: string, module: EntityConfigParam) => void,
    remove: (id: string) => void,
  },
  resources: {
    data: EditorEntityResource[],
    add: (module: EntityConfigResource) => void,
    update: (id: string, module: EntityConfigResource) => void,
    remove: (id: string) => void,
  },
  setEntityConfig: (config: EntityConfig) => void;
};
export const EntityConfigEditorContext = createContext<EntityConfigEditorStore>(
  {} as EntityConfigEditorStore,
);

export const useProvideEntityConfigEditor = (
  {
    entityConfig,
  }: {
    entityConfig: EntityConfig | null;
  },
): EntityConfigEditorStore => {
  const entityConfigToEditorEntityConfig = (config: EntityConfig | null): EditorEntityConfig => {
    if (!config) {
      return {
        keys: [],
        modules: [],
        params: [],
        resources: [],
      };
    }
    return {
      keys: Array.from(new Set([
        ...config.resources.map((resource) => resource.key),
        ...config.params.map((param) => param.key),
      ])),
      modules: config.modules.map((module) => ({ id: module.module_type, module })),
      params: config.params.map((param) => ({ id: param.key, param })),
      resources: config.resources.map((resource) => ({ id: resource.key, resource })),
    };
  };

  const [
    configState,
    setConfigState,
  ] = useState<EditorEntityConfig>(entityConfigToEditorEntityConfig(entityConfig));

  const setEntityConfig = (config: EntityConfig) => {
    setConfigState(entityConfigToEditorEntityConfig(config));
  };

  const addKey = (key: string) => {
    setConfigState((state) => ({
      ...state,
      keys: [...state.keys, key],
    }));
  };
  const removeKey = (key: string) => {
    setConfigState((state) => ({
      ...state,
      keys: state.keys.filter((k) => k !== key),
    }));
  };

  const addModule = (module: EntityConfigModule) => {
    setConfigState((state) => ({
      ...state,
      modules: [
        ...state.modules,
        { id: v4(), module },
      ],
    }));
  };
  const updateModule = (id: string, module: EntityConfigModule) => {
    setConfigState((state) => ({
      ...state,
      modules: state.modules.map((stateModule) => {
        if (stateModule.id !== id) { return stateModule; }
        return { id, module };
      }),
    }));
  };
  const removeModule = (id: string) => {
    setConfigState((state) => ({
      ...state,
      modules: state.modules
        .filter((module) => module.id !== id),
    }));
  };

  const addParam = (param: EntityConfigParam) => {
    setConfigState((state) => ({
      ...state,
      params: [
        ...state.params,
        { id: v4(), param },
      ],
    }));
  };
  const updateParam = (id: string, param: EntityConfigParam) => {
    setConfigState((state) => ({
      ...state,
      params: state.params.map((stateParam) => {
        if (stateParam.id !== id) { return stateParam; }
        return { id, param };
      }),
    }));
  };
  const removeParam = (id: string) => {
    setConfigState((state) => ({
      ...state,
      params: state.params
        .filter((param) => param.id !== id),
    }));
  };

  const addResource = (resource: EntityConfigResource) => {
    setConfigState((state) => ({
      ...state,
      resources: [
        ...state.resources,
        { id: v4(), resource },
      ],
    }));
  };
  const updateResource = (id: string, resource: EntityConfigResource) => {
    setConfigState((state) => ({
      ...state,
      resources: state.resources.map((stateResource) => {
        if (id !== stateResource.id) { return stateResource; }
        return { id, resource };
      }),
    }));
  };
  const removeResource = (id: string) => {
    setConfigState((state) => ({
      ...state,
      resources: state.resources.filter((resource) => resource.id === id),
    }));
  };

  return {
    keys: {
      data: configState.keys,
      add: addKey,
      remove: removeKey,
    },
    modules: {
      data: configState.modules,
      add: addModule,
      update: updateModule,
      remove: removeModule,
    },
    params: {
      data: configState.params,
      add: addParam,
      update: updateParam,
      remove: removeParam,
    },
    resources: {
      data: configState.resources,
      add: addResource,
      update: updateResource,
      remove: removeResource,
    },
    setEntityConfig,
  };
};

export const useEntityConfigEditor = () => useContext(EntityConfigEditorContext);
