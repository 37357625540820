import {
  FormField,
  NCInputText,
  NCSelect,
} from '@daupler/nexus-components';
import { EditorEntityResource } from '../../hooks/useEntityConfigEditor';
import { EntityConfigRef, EntityConfigResourceType } from '../../types/EntityConfig';

type ResourceBaseFormProps = {
  formState: {
    key: FormField<string>;
    displayName: FormField<string>;
    workgroupRef: FormField<EntityConfigRef | undefined>;
  };
  onChange: (name: string, value: string | EntityConfigRef) => void;
  workgroups: EditorEntityResource[];
};

export function ResourceBaseForm({
  formState,
  onChange,
  workgroups,
}: ResourceBaseFormProps) {
  return (
    <>
      <NCInputText
        label="Key"
        name={formState.key.name}
        onChange={(event) => onChange(formState.key.name, event.target.value)}
        value={formState.key.value}
        required
        disabled
      />
      <NCInputText
        label="Display Name"
        name={formState.displayName.name}
        onChange={(event) => onChange(formState.displayName.name, event.target.value)}
        value={formState.displayName.value}
        className="nc-l-mt_200_mobile"
        required
      />
      <NCSelect
        label="Workgroup"
        name={formState.workgroupRef.name}
        onChange={(event) => onChange(
          formState.workgroupRef.name,
          {
            key: event.target.value,
            type: EntityConfigResourceType.WORKGROUPS,
          },
        )}
        value={formState.workgroupRef.value?.key ?? ''}
        className="nc-l-mt_200_mobile"
        options={[
          { label: 'Choose one...', value: '' },
          ...workgroups.map((workgroup) => ({
            label: workgroup.resource.display_name,
            value: workgroup.id,
          })),
        ]}
      />
    </>
  );
}
