import { NCLabel } from '@daupler/nexus-components';
import { ConfigVisualEditorField } from './ConfigVisualEditorField';
import './ConfigVisualEditorModule.css';

type ConfigVisualEditorModuleProps = {
  moduleType: string;
  data: Record<string, unknown>;
};

export function ConfigVisualEditorModule({
  moduleType,
  data,
}: ConfigVisualEditorModuleProps) {
  return (
    <div className="config_visual_editor_module">
      <div className="nc-flex nc-flex--align_center">
        <NCLabel label="module" size={NCLabel.sizes.SM} color={NCLabel.colors.SUCCESS} />
        {' '}
        <span className="nc-t-sub_text_light_mobile nc-l-ml_100_mobile">
          {moduleType}
        </span>
      </div>
      <div className="nc-l-mt_100_mobile">
        <p className="nc-t-sub_text_medium_mobile">Data</p>
        {Object.entries(data).map(([key, value]) => (
          <ConfigVisualEditorField
            key={`${moduleType}-${key}`}
            fieldName={key}
            fieldValue={value}
            id={moduleType}
          />
        ))}
      </div>
    </div>
  );
}
