import { FormField, NCSelect, useForm } from '@daupler/nexus-components';
import { ResourceFormProps } from './resource-form-types';
import { useSyncResourceForm } from '../../hooks/useSyncResourceForm';
import { ResourceBaseForm } from './ResourceBaseForm';
import { getBaseResourceFormFields } from '../../utils/resource-forms';
import { EntityConfigParamRef, EntityConfigParamType } from '../../types/EntityConfig';

export function ServiceAreaForm({
  onChange,
  onValidate,
  params,
  resourceData,
  workgroups,
}: ResourceFormProps) {
  const featuresRef: FormField<EntityConfigParamRef | undefined> = {
    name: 'featuresRef',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: undefined,
  };
  const {
    formState,
    onChange: onFormChange,
    isFormValid,
  } = useForm({
    ...getBaseResourceFormFields({ resourceData }),
    featuresRef,
  });

  useSyncResourceForm({
    isFormValid,
    onChange,
    onValidate,
    resourceData: {
      display_name: formState.displayName.value,
      key: formState.key.value,
      _workgroup_ref: formState.workgroupRef.value,
      data: {
        _features_ref: formState.featuresRef.value,
      },
    },
  });

  return (
    <>
      <ResourceBaseForm
        formState={formState}
        onChange={onFormChange}
        workgroups={workgroups}
      />
      <NCSelect
        label="Features"
        className="nc-l-mt_200_mobile"
        name={formState.featuresRef.name}
        options={[
          { label: 'Choose one...', value: '' },
          ...params
            .filter(({ param }) => param.type === EntityConfigParamType.JSON)
            .map(({ param, id }) => ({
              label: param.key,
              value: id,
            })),
        ]}
        onChange={(event) => onFormChange(
          formState.featuresRef.name,
          {
            key: event.target.value,
            type: EntityConfigParamType.JSON,
          },
        )}
        value={formState.featuresRef.value?.key ?? ''}
      />
    </>
  );
}
