import { NCSelect } from '@daupler/nexus-components';
import { CciDispatchRuleProps } from './resource-form-types';
import { CciAlwaysDispatchParams, EntityConfigParamType } from '../../types/EntityConfig';

export function CciDispatchRuleFormAlwaysDispatch({
  onChange,
  params,
  value,
}: CciDispatchRuleProps) {
  const { _message_ref: messageRef } = (value ?? {}) as CciAlwaysDispatchParams;
  return (
    <NCSelect
      label="Message"
      className="nc-l-mt_200_mobile"
      name="always_dispatch-message"
      options={[
        { label: 'Choose one...', value: '' },
        ...params.filter(({ param }) => param.type === EntityConfigParamType.STRING)
          .map(({ param }) => ({
            label: param.key,
            value: param.key,
          })),
      ]}
      onChange={(event) => onChange({
        ...(value as CciAlwaysDispatchParams),
        _message_ref: {
          type: EntityConfigParamType.STRING,
          key: event.target.value,
        },
      })}
      value={messageRef.key}
    />
  );
}
