import { useState } from 'react';
import { NCInputText, NCSelect } from '@daupler/nexus-components';
import { v4 } from 'uuid';
import { IncidentEmailProcessorProps, UIIncidentEmailProcessorWashington811IrthParams } from './resource-form-types';
import { EntityConfigParamType } from '../../types/EntityConfig';
import { InputList } from '../InputList';

export function IncidentEmailProcessorWashington811Irth({
  onChange,
  params,
  value,
}: IncidentEmailProcessorProps) {
  const {
    tzNameId,
    emergency_etc_hours: emergencyEtcHours,
    emergencyClassifications,
    incident_version: incidentVersion,
    label_template: labelTemplate,
  } = (value as UIIncidentEmailProcessorWashington811IrthParams);

  const [
    newEmergencyClassifications,
    setNewEmergecyClassifications,
  ] = useState<[string, string]>(['', '']);

  return (
    <>
      <NCSelect
        label="TZ Name"
        name="washington_811_irth-tzname"
        className="nc-l-mt_200_mobile"
        options={[
          { label: 'Choose one...', value: '' },
          ...params
            .filter(({ param }) => param.type === EntityConfigParamType.STRING)
            .map(({ param, id }) => ({
              label: param.key,
              value: id,
            })),
        ]}
        value={tzNameId}
        onChange={(event) => ({
          ...(value as UIIncidentEmailProcessorWashington811IrthParams),
          tzNameId: event.target.value,
        })}
      />
      <NCInputText
        label="Emergency ETC Hours"
        name="washington_811_irth-emergency_etc_hours"
        type="number"
        step={1}
        onChange={(event) => {
          onChange({
            ...(value as UIIncidentEmailProcessorWashington811IrthParams),
            emergency_etc_hours: parseInt(event.target.value, 10),
          });
        }}
        value={emergencyEtcHours}
      />
      <InputList<{ emergencyClassification: [string, string]; id: string; }>
        data={(emergencyClassifications ?? [])}
        label="Emergency Classifications"
        onAdd={() => {
          onChange({
            ...(value as UIIncidentEmailProcessorWashington811IrthParams),
            emergencyClassifications: [
              ...(
                value as UIIncidentEmailProcessorWashington811IrthParams).emergencyClassifications,
              { id: v4(), emergencyClassification: newEmergencyClassifications },
            ],
          });
        }}
        onRemove={(data) => {
          onChange({
            ...(value as UIIncidentEmailProcessorWashington811IrthParams),
            emergencyClassifications: data,
          });
        }}
        renderItem={({ emergencyClassification }) => (
          <p>{JSON.stringify(emergencyClassification)}</p>
        )}
        placeholder="No Emergency Classifications here yet!"
      >
        <NCInputText
          name="south_carolina_811-new_emergency_status"
          label="Emergency Status"
          value={newEmergencyClassifications}
          onChange={(event) => setNewEmergecyClassifications((state) => [
            event.target.value, state[1]])}
        />
        <NCInputText
          name="south_carolina_811-new_emergency_status"
          label="Emergency Status"
          value={newEmergencyClassifications}
          onChange={(event) => setNewEmergecyClassifications((state) => [
            state[0], event.target.value])}
        />
      </InputList>
      <NCInputText
        label="Incident Version"
        name="washington_811_irth-incident_version"
        className="nc-l-mt_200_mobile"
        onChange={(event) => {
          onChange({
            ...(value as UIIncidentEmailProcessorWashington811IrthParams),
            incident_version: event.target.value,
          });
        }}
        value={incidentVersion}
      />
      <NCInputText
        label="Label Template"
        name="washington_811_irth-label_template"
        className="nc-l-mt_200_mobile"
        onChange={(event) => {
          onChange({
            ...(value as UIIncidentEmailProcessorWashington811IrthParams),
            label_template: event.target.value,
          });
        }}
        value={labelTemplate}
      />
    </>
  );
}
