import {
  FormField,
  NCButton,
  NCInputText,
  NCModal,
  useForm,
} from '@daupler/nexus-components';
import { v4 } from 'uuid';
import { useState } from 'react';
import { EntityConfigResource, EntityConfigResourceType } from '../types/EntityConfig';
import { logger } from '../utils/logger';
import './ConfigVisualEditorResourceModal.css';
import { ConfigVisualEditorResourceForms } from './ConfigVisualEditorResourceForms';
import { EditorEntityParam, EditorEntityResource } from '../hooks/useEntityConfigEditor';

enum View {
  CHOOSE_RESOURCE = 'CHOOSE_RESOURCE',
  CONFIGURE_RESOURCE = 'CONFIGURE_RESOURCE',
}

type ConfigVisualEditorResourceModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onCloseFinished: () => void;
  onSubmit: (resource: EntityConfigResource) => Promise<void>;
  params: EditorEntityParam[];
  resource?: EditorEntityResource;
  resources: EditorEntityResource[];
  title: string;
};

export function ConfigVisualEditorResourceModal({
  isOpen,
  onClose,
  onCloseFinished,
  onSubmit,
  params,
  resource,
  resources,
  title,
}: ConfigVisualEditorResourceModalProps) {
  const [view, setView] = useState(View.CHOOSE_RESOURCE);

  const type: FormField<EntityConfigResourceType> = {
    invalidMessage: '',
    name: 'type',
    validate: (value) => !!value,
    validMessage: '',
    value: resource?.resource.type ?? '' as EntityConfigResourceType,
    isValid: !!resource?.resource.type,
  };
  const resourceData: FormField<Omit<EntityConfigResource, 'type'> | null> = {
    invalidMessage: '',
    name: 'resourceData',
    validate: (_, state) => state.resourceData.isValid ?? false,
    validMessage: '',
    value: resource?.resource ?? { key: v4() } as EntityConfigResource,
  };

  const {
    formState,
    isFormValid,
    onChange,
    validateField,
    validateForm,
  } = useForm({ resourceData, type });

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    validateField(formState.type.name);
    if (view === View.CHOOSE_RESOURCE && formState.type.isValid) {
      setView(View.CONFIGURE_RESOURCE);
      return;
    }

    validateForm();
    if (!isFormValid()) { return; }

    try {
      await onSubmit({
        type: formState.type.value,
        ...(formState.resourceData.value ?? {}),
      } as EntityConfigResource);
      onClose();
    } catch (err) {
      logger.error((err as Error).message, err);
    }
  };

  const handleCancel = () => {
    if (view === View.CHOOSE_RESOURCE) {
      onClose();
    } else {
      setView(View.CHOOSE_RESOURCE);
    }
  };

  const canSubmit = () => {
    if (view === View.CHOOSE_RESOURCE) {
      return formState.type.isValid;
    }
    return isFormValid();
  };

  const [resourceSearchFilter, setResourceSearchFilter] = useState('');

  return (
    <form onSubmit={handleSubmit}>
      <NCModal
        width={NCModal.widths.FILL}
        height={NCModal.heights.FILL}
        closeLabel="Close"
        isOpen={isOpen}
        onClose={onClose}
        onCloseFinished={onCloseFinished}
        title={title}
        footerChildren={(
          <div className="nc-flex nc-flex--align_center nc-flex--justify_between nc-l-pa_utilities_225_mobile">
            <NCButton
              appearance={NCButton.appearances.OUTLINE}
              color={NCButton.colors.GREY}
              width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
              onClick={handleCancel}
            >
              {view === View.CHOOSE_RESOURCE ? 'Cancel' : 'Back'}
            </NCButton>
            <NCButton
              appearance={NCButton.appearances.SOLID}
              color={NCButton.colors.PRIMARY}
              width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
              type="submit"
              disabled={!canSubmit()}
            >
              {view === View.CHOOSE_RESOURCE ? 'Next' : 'Submit'}
            </NCButton>
          </div>
        )}
      >
        {view === View.CHOOSE_RESOURCE ? (
          <>
            <div className="nc-flex nc-flex--align_end nc-flex--justify_between nc-l-mt_100_mobile">
              <p className="nc-t-h5_medium_mobile">Choose Resource</p>
              <NCInputText
                label="Search for Resource"
                labelHidden
                value={resourceSearchFilter}
                onChange={(event) => { setResourceSearchFilter(event.target.value); }}
                name="resource-search"
                tag={(<i className="fa-solid fa-search fa-fw" />)}
              />
            </div>
            <div className="nc-l-mt_200_mobile config_visual_editor_resource_modal__grid">
              {Object.values(EntityConfigResourceType)
                .filter((resourceType) => resourceType.toLowerCase().includes(resourceSearchFilter))
                .map((resourceType) => (
                  <div key={resourceType} className="config_visual_editor_resource_modal__item">
                    <p className="nc-t-body_medium_mobile">
                      {resourceType}
                    </p>
                    <p className="nc-t-sub_text_regular_mobile">
                      description
                    </p>
                    <NCButton
                      width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
                      size={[[NCButton.breakpoints.MOBILE, NCButton.sizes.SM]]}
                      appearance={NCButton.appearances.OUTLINE}
                      color={NCButton.colors.PRIMARY}
                      active={formState.type.value === resourceType}
                      className="nc-l-mt_100_mobile"
                      disabled={formState.type.value && formState.type.value !== resourceType}
                      onClick={() => {
                        if (formState.type.value === resourceType) {
                          onChange(formState.type.name, '' as EntityConfigResourceType);
                        } else {
                          onChange(formState.type.name, resourceType);
                        }
                      }}
                    >
                      {formState.type.value === resourceType
                        ? 'Unselect'
                        : 'Select'}
                    </NCButton>
                  </div>
                ))}
            </div>
          </>
        ) : null}
        {view === View.CONFIGURE_RESOURCE ? (
          <div>
            <ConfigVisualEditorResourceForms
              onChange={(value) => {
                onChange(
                  formState.resourceData.name,
                  value,
                );
              }}
              onValidate={(result) => {
                validateField(formState.resourceData.name, result);
              }}
              type={formState.type.value}
              params={params}
              resourceData={formState.resourceData.value}
              resources={resources}
              workgroups={resources.filter(
                (workgroup) => workgroup
                  .resource.type === EntityConfigResourceType.ESCALATION_TREES,
              )}
            />
          </div>
        ) : null}
      </NCModal>
    </form>
  );
}
