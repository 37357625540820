import { NCInputCheckbox, NCInputText } from '@daupler/nexus-components';
import { ConfigVisualEditorFieldPhone } from './ConfigVisualEditorFieldPhone';
import { ConfigVisualEditorFieldJson } from './ConfigVisualEditorFieldJson';
import { ConfigVisualEditorFieldExtent, Extent } from './ConfigVisualEditorFieldExtent';
import { ConfigVisualEditorFieldImage } from './ConfigVisualEditorFieldImage';
import './ConfigVisualEditorField.css';

type ConfigVisualEditorFieldProps = {
  id: string;
  fieldName: string;
  fieldValue: unknown;
  onChange?: (value: unknown) => void;
  preview?: boolean;
  type?: string;
};

export function ConfigVisualEditorField({
  id,
  fieldName,
  fieldValue,
  onChange,
  preview,
  type,
}: ConfigVisualEditorFieldProps) {
  const name = `${id}-${fieldName}`;

  if (type === 'string') {
    if (preview) {
      return (
        <div className="config_visual_editor_field__preview">{(fieldValue as { text: string }).text}</div>
      );
    }
    return (
      <NCInputText
        label={fieldName}
        name={name}
        value={(fieldValue as { text: string; }).text}
        onChange={(event) => {
          onChange?.({ text: event.target.value });
        }}
      />
    );
  }
  if (type === 'phone_number') {
    if (preview) {
      return (
        <div className="config_visual_editor_field__preview">
          {(fieldValue as { phone_number: string; display_name: string; }).display_name}
          <br />
          {(fieldValue as { phone_number: string; display_name: string; }).phone_number}
        </div>
      );
    }
    return (
      <ConfigVisualEditorFieldPhone
        name={fieldName}
        value={fieldValue as { phone_number: string; display_name: string; } ?? {}}
        onChange={(v) => {
          onChange?.(v);
        }}
      />
    );
  }
  if (type === 'json') {
    if (preview) {
      return (
        <div className="config_visual_editor_field__preview">Preview Unavailable</div>
      );
    }
    return (
      <ConfigVisualEditorFieldJson
        label={fieldName}
        name={fieldName}
        value={JSON.stringify(fieldValue)}
        onChange={(event) => {
          onChange?.(event.target.value);
        }}
      />
    );
  }
  if (type === 'extent') {
    if (preview) {
      return (
        <div className="config_visual_editor_field__preview">
          {(fieldValue as Extent).lat_max}
          ,
          {(fieldValue as Extent).lng_max}
          {'; '}
          {(fieldValue as Extent).lat_min}
          ,
          {(fieldValue as Extent).lng_min}
        </div>
      );
    }
    return (
      <ConfigVisualEditorFieldExtent
        name={fieldName}
        value={fieldValue as Extent}
        onChange={(v) => {
          onChange?.(v);
        }}
      />
    );
  }
  if (type === 'image') {
    if (preview) {
      return (
        <div className="config_visual_editor_field__preview">
          {(fieldValue as { public_url: string })?.public_url}
        </div>
      );
    }
    return (
      <ConfigVisualEditorFieldImage
        src={(fieldValue as { public_url: string })?.public_url}
        onChange={(v) => {
          onChange?.({ public_url: v });
        }}
      />
    );
  }

  if (typeof fieldValue === 'string') {
    return (
      <NCInputText
        label={fieldName}
        name={name}
        value={fieldValue as string}
      />
    );
  }
  if (typeof fieldValue === 'boolean') {
    return (
      <NCInputCheckbox
        label={fieldName}
        name={name}
        checked={fieldValue}
      />
    );
  }
  return null;
}
