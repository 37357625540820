import { useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { NCLoadingIndicator } from '@daupler/nexus-components';
import { SegmentedControl } from '../components/SegmentedControl';
import { EntitiesIdRouteOld } from '../views/entities-id-route/OldView';
import { EntitiesIdRouteNew } from '../views/entities-id-route/NewView';
import './EntitiesIdRoute.css';
import { Entity, EntityDetail } from '../types/Entity';
import { config } from '../config';
import { DauplerApi } from '../lib/daupler-api';
import { useAuthentication } from '../hooks/useAuthentication';
import { logger } from '../utils/logger';

enum View {
  OLD = 'OLD',
  NEW = 'NEW',
}

export function EntitiesIdRoute() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [view, setView] = useState(searchParams.get('view') ?? View.OLD);
  useEffect(() => {
    if (!searchParams.get('view')) { return; }
    if (searchParams.get('view') !== view) {
      setView(searchParams.get('view') as View);
    }
  }, [searchParams, view]);

  const dauplerApi = useRef(new DauplerApi({
    baseUrl: config.dauplerApiBaseUrl,
    fetch: fetch.bind(window),
  }));

  const [isLoading, setIsLoading] = useState(false);
  const { authToken } = useAuthentication();
  const { id } = useParams();
  const [entity, setEntity] = useState<EntityDetail | null>(null);
  const [timezones, setTimezones] = useState<string[] | null>(null);
  const [entities, setEntities] = useState<Entity[] | null>(null);
  useEffect(() => {
    const getEntities = async () => {
      if (!authToken) { return; }
      if (!id) { return; }
      setIsLoading(true);
      try {
        const [
          entityResponse,
          tzNamesResponse,
          entitiesResponse,
        ] = await Promise.all([
          dauplerApi.current.getEntity(authToken, id),
          dauplerApi.current.getTimezones(authToken),
          dauplerApi.current.getEntities(authToken),
        ]);
        setEntity(entityResponse);
        setTimezones(tzNamesResponse);
        setEntities(entitiesResponse);
      } catch (err) {
        logger.error((err as Error).message, err);
      } finally {
        setIsLoading(false);
      }
    };
    if (isLoading) { return; }
    if (entity) { return; }
    getEntities();
  }, [authToken, entity, id, isLoading]);

  if (isLoading) { return <NCLoadingIndicator label="Loading Entity..." blockUi />; }

  return (
    <div>
      {view === View.OLD ? (
        <EntitiesIdRouteOld
          timezones={timezones}
          entities={entities}
          entity={entity}
        />
      ) : null}
      {view === View.NEW ? (
        <EntitiesIdRouteNew entity={entity} />
      ) : null}
      <div className="entities_id_route__view_selector">
        <SegmentedControl
          segments={[
            { label: 'Old Editor', value: View.OLD },
            { label: 'New Editor', value: View.NEW },
          ]}
          value={view}
          onChange={(v) => {
            const params = new URLSearchParams(searchParams);
            params.set('view', v);
            setSearchParams(params);
          }}
        />
      </div>
    </div>
  );
}
