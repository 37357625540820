import { NCSelect } from '@daupler/nexus-components';
import { CciDispatchRuleProps } from './resource-form-types';
import { CciNeverDispatchParams, EntityConfigParamType } from '../../types/EntityConfig';

export function CciDispatchRuleFormNeverDispatch({
  onChange,
  params,
  value,
}: CciDispatchRuleProps) {
  const { _message_ref: messageRef } = (value ?? {}) as CciNeverDispatchParams;
  return (
    <NCSelect
      label="Message"
      className="nc-l-mt_200_mobile"
      name="never_dispatch-message"
      options={[
        { label: 'Choose one...', value: '' },
        ...params.filter(({ param }) => param.type === EntityConfigParamType.STRING)
          .map(({ param, id }) => ({
            label: param.key,
            value: id,
          })),
      ]}
      onChange={(event) => onChange({
        ...(value as CciNeverDispatchParams),
        _message_ref: {
          key: event.target.value,
          type: EntityConfigParamType.STRING,
        },
      })}
      value={messageRef.key}
    />
  );
}
