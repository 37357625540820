import React from 'react';
import {
  FormField,
  NCButton,
  NCInputText,
  NCSelect,
  NCWell,
  useForm,
} from '@daupler/nexus-components';
import './ConfigVisualEditorParamForm.css';
import { ConfigVisualEditorKey } from './ConfigVisualEditorKey';
import { ConfigVisualEditorField } from './ConfigVisualEditorField';
import { EntityConfigParam, EntityConfigParamType } from '../types/EntityConfig';

type ConfigVisualEditorParamFormProps = {
  type?: string;
  keys: [string, number][];
  onAddKey: (key: string) => void;
  onRemoveKey: (key: string) => void;
  onSubmit: (param: EntityConfigParam) => void;
  paramKey?: string;
  description?: string;
  value?: unknown;
};

export function ConfigVisualEditorParamForm({
  value,
  keys,
  onAddKey,
  onRemoveKey,
  onSubmit,
  paramKey,
  description,
  type,
}: ConfigVisualEditorParamFormProps) {
  const paramFormDescription: FormField<string> = {
    name: 'paramFormDescription',
    validate: (v) => !!v,
    value: description ?? '',
    invalidMessage: 'Description is required',
    validMessage: '',
  };
  const paramFormKey: FormField<string> = {
    name: 'paramFormKey',
    validate: (v) => !!v,
    value: paramKey ?? '',
    invalidMessage: 'Key is required',
    validMessage: '',
  };
  const paramFormType: FormField<string> = {
    name: 'paramFormType',
    validate: (v) => !!v,
    value: type ?? '',
    invalidMessage: 'Type is required',
    validMessage: '',
  };
  const paramFormValue: FormField<unknown> = {
    name: 'paramFormValue',
    validate: (v) => !!v,
    value: value ?? '',
    invalidMessage: 'Value is required',
    validMessage: '',
  };
  const {
    formState,
    getFormValues,
    isFormValid,
    onChange,
    validateForm,
  } = useForm({
    paramFormDescription,
    paramFormKey,
    paramFormType,
    paramFormValue,
  });

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    validateForm();
    if (!isFormValid()) { return; }
    const values = getFormValues();
    onSubmit({
      description: values.paramFormDescription,
      key: values.paramFormKey,
      type: values.paramFormType as EntityConfigParamType,
      value: values.paramFormValue,
    });
  };

  return (
    <form className="config_visual_editor_param" onSubmit={handleSubmit}>
      <NCInputText
        className="nc-l-mt_200_mobile"
        label="Description"
        name={formState.paramFormDescription.name}
        value={formState.paramFormDescription.value}
        onChange={(event) => {
          onChange(formState.paramFormDescription.name, event.target.value);
        }}
        isValid={formState.paramFormDescription.isValid}
        hint={formState.paramFormDescription.message}
      />

      <ConfigVisualEditorKey
        className="nc-l-mt_200_mobile"
        onAddKey={onAddKey}
        onRemoveKey={onRemoveKey}
        onSelectKey={(key) => {
          onChange(formState.paramFormKey.name, key);
        }}
        keys={keys}
        value={formState.paramFormKey.value}
        name={formState.paramFormKey.name}
      />

      <div className="nc-l-mt_200_mobile">
        <NCSelect
          label="Value Type"
          options={[
            { label: 'Choose one...', value: '' },
            ...Object.values(EntityConfigParamType).map((v) => ({
              label: v,
              value: v,
            })),
          ]}
          name={formState.paramFormType.name}
          value={formState.paramFormType.value}
          onChange={(event) => {
            onChange(formState.paramFormType.name, event.target.value);
          }}
          isValid={formState.paramFormType.isValid}
          hint={formState.paramFormType.message}
        />
        {formState.paramFormType.value ? (
          <>
            <NCWell className="nc-l-mt_100_mobile">
              <ConfigVisualEditorField
                fieldName="Value"
                fieldValue={formState.paramFormValue.value}
                id="param-editor-value"
                type={formState.paramFormType.value}
                onChange={(v) => {
                  onChange(formState.paramFormValue.name, v);
                }}
              />
            </NCWell>
            {!formState.paramFormValue.isValid ? <p className="nc-t-error">{formState.paramFormValue.message}</p> : null}
          </>
        ) : null}
      </div>

      <NCButton
        type="submit"
        color={NCButton.colors.PRIMARY}
        className="nc-l-mt_300_mobile"
        disabled={!isFormValid()}
      >
        Save
      </NCButton>
    </form>
  );
}
