import { NCSelect } from '@daupler/nexus-components';
import { CciDispatchRuleProps } from './resource-form-types';
import { CciRedirectParams, EntityConfigParamType } from '../../types/EntityConfig';

export function CciDispatchRuleFormNeverRedirect({
  onChange,
  params,
  value,
}: CciDispatchRuleProps) {
  const { _redirect_to_ref_list: refList } = (value ?? {}) as CciRedirectParams;
  return (
    <NCSelect
      label="Redirects"
      className="nc-l-mt_200_mobile"
      multiple
      name="redirect-message"
      options={[
        { label: 'Choose one...', value: '' },
        ...params.filter(({ param }) => param.type === EntityConfigParamType.STRING)
          .map(({ param, id }) => ({
            label: param.key,
            value: id,
          })),
      ]}
      onChange={(event) => {
        const values = Array.from(event.target.options)
          .filter((option) => option.selected)
          .map((option) => option.value);
        onChange({
          ...(value as CciRedirectParams),
          _redirect_to_ref_list: values.map((val) => ({
            key: val,
            type: EntityConfigParamType.STRING,
          })),
        });
      }}
      value={refList.map((ref) => ref.key)}
    />
  );
}
