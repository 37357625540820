import {
  FormField,
  NCSelect,
  useForm,
} from '@daupler/nexus-components';
import { ModuleFormProps } from './module-form-types';
import {
  EntityConfigModuleNotify,
  EntityConfigRef,
  EntityConfigResourceType,
  NotifyModuleStatus,
} from '../../types/EntityConfig';
import { useSyncModuleForm } from '../../hooks/useSyncModuleForm';

export function NotifyForm({
  module,
  onChange,
  onValidate,
  resources,
}: ModuleFormProps) {
  const {
    data: {
      _service_area_ref: serviceAreaRefValue,
      status: statusValue,
    },
  } = module as EntityConfigModuleNotify ?? { data: {} };
  const serviceAreaRef: FormField<EntityConfigRef | undefined> = {
    invalidMessage: '',
    name: 'serviceAreaRef',
    validate: () => true,
    validMessage: '',
    value: serviceAreaRefValue ?? undefined,
  };
  const status: FormField<NotifyModuleStatus | ''> = {
    invalidMessage: '',
    name: 'status',
    validate: () => true,
    validMessage: '',
    value: statusValue ?? '',
  };

  const {
    formState,
    isFormValid,
    onChange: onFormChange,
  } = useForm({
    serviceAreaRef,
    status,
  });
  useSyncModuleForm({
    isFormValid,
    module: {
      data: {
        _service_area_ref: formState.serviceAreaRef.value,
        status: formState.status.value as NotifyModuleStatus,
      },
    },
    onChange,
    onValidate,
  });

  return (
    <>
      <NCSelect
        className="nc-l-mt_200_mobile"
        label="Extent"
        name={formState.status.name}
        options={[
          { value: '', label: 'Choose one...' },
          ...Object.values(NotifyModuleStatus)
            .map((value) => ({ value, label: value })),
        ]}
        onChange={(event) => {
          onFormChange(
            formState.status.name,
            event.target.value as NotifyModuleStatus,
          );
        }}
        value={formState.status.value}
      />
      <NCSelect
        className="nc-l-mt_200_mobile"
        label="Service Area"
        name={formState.serviceAreaRef.name}
        options={resources
          .filter(({ resource }) => resource.type === EntityConfigResourceType.SERVICE_AREAS)
          .map(({ resource }) => ({
            label: resource.display_name,
            value: resource.key,
          }))}
        onChange={(event) => {
          onFormChange(
            formState.serviceAreaRef.name,
            {
              key: event.target.value,
              type: EntityConfigResourceType.SERVICE_AREAS,
            },
          );
        }}
        value={formState.serviceAreaRef.value?.key ?? ''}
      />
    </>
  );
}
