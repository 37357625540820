import { useState } from 'react';
import { useNavigate } from 'react-router';
import { ConfigVisualEditorResourceModal } from '../components/ConfigVisualEditorResourceModal';
import { useEntityConfigEditor } from '../hooks/useEntityConfigEditor';
import { EntityConfigResource } from '../types/EntityConfig';

export function EntitiesAddResourceRoute() {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const navigate = useNavigate();

  const { params, resources } = useEntityConfigEditor();

  const handleSubmit = async (resource: EntityConfigResource) => {
    resources.add(resource);
  };

  return (
    <ConfigVisualEditorResourceModal
      title="Add Resource"
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      onCloseFinished={() => navigate(-1)}
      onSubmit={handleSubmit}
      params={params.data}
      resources={resources.data}
    />
  );
}
