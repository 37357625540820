import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
} from 'react-router-dom';
import { NCErrorBoundary } from '@daupler/nexus-components';
import { ErrorNotFound } from './components/ErrorNotFound';
import { ErrorRenderer } from './components/ErrorRenderer';
import { YamlBuilderRoute } from './routes/YamlBuilderRoute';
import { EntitiesRoute } from './routes/EntitiesRoute';
import { EntitiesIdRoute } from './routes/EntitiesIdRoute';
import { ProtectedRoute } from './components/ProtectedRoute';
import { LoginRoute } from './routes/LoginRoute';
import { DauplerApi } from './lib/daupler-api';
import { config } from './config';
import { ProvideAuthentication } from './providers/ProvideAuthentication';
import { EntitiesAddEntityRoute } from './routes/EntitiesAddEntityRoute';
import { EntitiesAddResourceRoute } from './routes/EntitiesAddResourceRoute';
import { ProvideEntityConfigEditor } from './providers/ProvideEntityConfigEditor';
import { EntitiesEditResourceRoute } from './routes/EntitiesEditResourceRoute';
import { EntitiesAddModuleRoute } from './routes/EntitiesAddModuleRoute';
import { EntitiesEditModuleRoute } from './routes/EntitiesEditModuleRoute';

function App() {
  const router = createBrowserRouter(createRoutesFromElements(
    <Route
      errorElement={<ErrorRenderer />}
      element={(
        <NCErrorBoundary
          renderError={(error) => { throw error; }}
          catchError={() => {}}
        >
          <ProvideAuthentication
            api={new DauplerApi({ baseUrl: config.dauplerApiBaseUrl, fetch: fetch.bind(window) })}
          >
            <Outlet />
          </ProvideAuthentication>
        </NCErrorBoundary>
      )}
    >
      <Route path="/login" element={<LoginRoute />} />
      <Route path="/config/entities" element={<ProtectedRoute><Outlet /></ProtectedRoute>}>
        <Route path="add-entity" element={<EntitiesAddEntityRoute />} />
        <Route
          path=":id"
          element={(
            <ProvideEntityConfigEditor>
              <EntitiesIdRoute />
              <Outlet />
            </ProvideEntityConfigEditor>
          )}
        >
          <Route path="resources/:resourceId" element={<EntitiesEditResourceRoute />} />
          <Route path="add-resource" element={<EntitiesAddResourceRoute />} />
          <Route path="modules/:moduleId" element={<EntitiesEditModuleRoute />} />
          <Route path="add-module" element={<EntitiesAddModuleRoute />} />
        </Route>
        <Route index element={<EntitiesRoute />} />
      </Route>
      <Route index element={<YamlBuilderRoute />} />
      <Route path="*" element={<ErrorNotFound />} />
    </Route>,
  ));
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
