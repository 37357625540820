import {
  FormField,
  NCSelect,
  useForm,
} from '@daupler/nexus-components';
import { ResourceFormProps } from './resource-form-types';
import { useSyncResourceForm } from '../../hooks/useSyncResourceForm';
import { ResourceBaseForm } from './ResourceBaseForm';
import { getBaseResourceFormFields, getResourceRefFromId } from '../../utils/resource-forms';
import { EntityConfigResourceDispatchPlaybook, EntityConfigResourceType } from '../../types/EntityConfig';

export function DispatchPlaybookForm({
  onChange,
  onValidate,
  resourceData,
  resources,
  workgroups,
}: ResourceFormProps) {
  const {
    data: {
      _actions_ref_list: actionsRefListValue,
    },
  } = resourceData as EntityConfigResourceDispatchPlaybook ?? { data: {} };
  const actionIds: FormField<string[]> = {
    name: 'actionIds',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: actionsRefListValue.map(({ key }) => key) ?? [],
  };
  const {
    formState,
    onChange: onFormChange,
    isFormValid,
  } = useForm({
    ...getBaseResourceFormFields({ resourceData }),
    actionIds,
  });

  useSyncResourceForm({
    isFormValid,
    onChange,
    onValidate,
    resourceData: {
      display_name: formState.displayName.value,
      key: formState.key.value,
      _workgroup_ref: formState.workgroupRef.value ?? undefined,
      data: {
        _actions_ref_list: formState.actionIds.value
          .map((actionId) => getResourceRefFromId(actionId, resources)),
      },
    },
  });

  return (
    <>
      <ResourceBaseForm
        formState={formState}
        onChange={onFormChange}
        workgroups={workgroups}
      />
      <NCSelect
        label="Actions"
        multiple
        name={formState.actionIds.name}
        className="nc-l-mt_200_mobile"
        onChange={(event) => onFormChange(
          formState.actionIds.name,
          Array.from(event.target.options)
            .filter((option) => option.selected)
            .map((option) => option.value),
        )}
        value={formState.actionIds.value}
        options={resources
          .filter(({ resource }) => resource.type === EntityConfigResourceType.DISPATCH_ACTIONS)
          .map(({ resource, id }) => ({
            label: resource.display_name,
            value: id,
          }))}
      />
    </>
  );
}
