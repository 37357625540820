import { NCInputText, NCSelect } from '@daupler/nexus-components';
import {
  EntityConfigResourceDispatchActionDispatchActionMultiAckTransitionalParams,
  EntityConfigResourceType,
  MultiAckSupervisorMode,
} from '../../types/EntityConfig';
import { DispatchActionProps } from './resource-form-types';

export function DispatchActionFormActionMultiAckTransitional({
  onChange,
  value,
  resources,
}: DispatchActionProps) {
  const {
    _escalation_tree_id_ref: escalationTreeIdRef,
    team_display_name: teamDisplayName,
    max_nb_responders_required: maxNbRespondersRequired,
    supervisor_mode: supervisorMode,
  } = value as EntityConfigResourceDispatchActionDispatchActionMultiAckTransitionalParams;
  return (
    <>
      <NCSelect
        label="Escalation Tree"
        name="multi_ack_transitional-escalation_tree_id"
        options={[
          { value: '', label: 'Choose one...' },
          ...resources
            .filter(({ resource }) => resource.type === EntityConfigResourceType.ESCALATION_TREES)
            .map(({ id, resource }) => ({
              label: resource.display_name,
              value: id,
            })),
        ]}
        value={escalationTreeIdRef.key}
        onChange={(event) => onChange({
          ...value as EntityConfigResourceDispatchActionDispatchActionMultiAckTransitionalParams,
          _escalation_tree_id_ref: {
            key: event.target.value,
            type: EntityConfigResourceType.ESCALATION_TREES,
          },
        })}
      />
      <NCInputText
        label="Team Display Name"
        name="multi_ack_transitional-team_display_name"
        value={teamDisplayName}
        onChange={(event) => onChange({
          ...value as EntityConfigResourceDispatchActionDispatchActionMultiAckTransitionalParams,
          team_display_name: event.target.value,
        })}
        className="nc-l-mt_200_mobile"
      />
      <NCInputText
        label="Max NB Responders Required"
        name="distribute_by_service_area-max_nb_responders_required"
        value={maxNbRespondersRequired?.toString()}
        type="number"
        step={1}
        onChange={(event) => onChange({
          ...value as EntityConfigResourceDispatchActionDispatchActionMultiAckTransitionalParams,
          max_nb_responders_required: parseInt(event.target.value, 10),
        })}
        className="nc-l-mt_200_mobile"
      />
      <NCSelect
        label="Supervisor Mode"
        className="nc-l-mt_200_mobile"
        name="multi_ack_transitional-supervisor_mode"
        options={[
          { value: '', label: 'Choose one...' },
          ...Object.values(MultiAckSupervisorMode)
            .map((multiAckSupervisorMode) => ({
              label: multiAckSupervisorMode,
              value: multiAckSupervisorMode,
            })),
        ]}
        value={supervisorMode}
        onChange={(event) => onChange({
          ...value as EntityConfigResourceDispatchActionDispatchActionMultiAckTransitionalParams,
          supervisor_mode: event.target.value as MultiAckSupervisorMode,
        })}
      />
    </>
  );
}
