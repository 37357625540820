import { useState } from 'react';
import {
  FormField,
  NCInputCheckbox,
  NCSelect,
  useForm,
} from '@daupler/nexus-components';
import { ResourceFormProps } from './resource-form-types';
import { useSyncResourceForm } from '../../hooks/useSyncResourceForm';
import { ResourceBaseForm } from './ResourceBaseForm';
import { getBaseResourceFormFields } from '../../utils/resource-forms';
import {
  DispatchPolicyRule,
  EntityConfigRef,
  EntityConfigResourceDispatchPolicy,
  EntityConfigResourceType,
} from '../../types/EntityConfig';
import { InputList } from '../InputList';

export function DispatchPolicyForm({
  onChange,
  onValidate,
  resourceData,
  resources,
  workgroups,
}: ResourceFormProps) {
  const getEmptyDispatchPolicyRule = () => ({
    id: '',
    _condition_ref: undefined,
    _playbook_ref: undefined,
    stop_cascade: false,
  });
  const [
    newDispatchPolicyRule,
    setNewDispatchPolicyRule,
  ] = useState<DispatchPolicyRule>(getEmptyDispatchPolicyRule());

  const {
    data: {
      _default_playbook_ref: defaultPlaybookRefValue,
      rules: rulesValue,
    },
  } = resourceData as EntityConfigResourceDispatchPolicy ?? { data: {} };

  const defaultPlaybookRef: FormField<EntityConfigRef | null> = {
    name: 'defaultPlaybookRef',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: defaultPlaybookRefValue ?? null,
  };
  const rules: FormField<DispatchPolicyRule[]> = {
    name: 'rules',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: rulesValue ?? [],
  };
  const {
    formState,
    onChange: onFormChange,
    isFormValid,
  } = useForm({
    ...getBaseResourceFormFields({ resourceData }),
    defaultPlaybookRef,
    rules,
  });

  useSyncResourceForm({
    isFormValid,
    onChange,
    onValidate,
    resourceData: {
      display_name: formState.displayName.value,
      key: formState.key.value,
      _workgroup_ref: formState.workgroupRef.value ?? undefined,
      data: {
        _default_playbook_ref: formState.defaultPlaybookRef.value,
        rules: formState.rules.value,
      },
    },
  });

  const addRule = () => {
    onFormChange(
      formState.rules.name,
      [
        ...formState.rules.value,
        newDispatchPolicyRule,
      ],
    );
  };
  const removeRule = (data: DispatchPolicyRule[]) => {
    onFormChange(
      formState.rules.name,
      data,
    );
  };

  const {
    _condition_ref: conditionRef,
    _playbook_ref: playbookRef,
  } = newDispatchPolicyRule;

  return (
    <>
      <ResourceBaseForm
        formState={formState}
        onChange={onFormChange}
        workgroups={workgroups}
      />
      <NCSelect
        label="Default Playbook"
        name={formState.defaultPlaybookRef.name}
        className="nc-l-mt_200_mobile"
        onChange={(event) => onFormChange(
          formState.defaultPlaybookRef.name,
          event.target.value,
        )}
        value={formState.defaultPlaybookRef.value?.key ?? ''}
        options={resources
          .filter(({ resource }) => resource.type === EntityConfigResourceType.DISPATCH_PLAYBOOKS)
          .map(({ resource, id }) => ({
            label: resource.display_name,
            value: id,
          }))}
      />

      <InputList<DispatchPolicyRule>
        data={formState.rules.value}
        label="Rules"
        onAdd={addRule}
        onRemove={removeRule}
        renderItem={(rule) => (
          <ul>
            <li>
              Condition:
              {' '}
              {resources.find(({ resource }) => {
                const { _condition_ref: ref } = rule;
                return resource.type === EntityConfigResourceType.DISPATCH_CONDITIONS
                  && ref?.key === resource.key;
              })?.resource.display_name}
            </li>
            <li>
              Playbook:
              {' '}
              {resources.find(({ resource }) => {
                const { _playbook_ref: ref } = rule;
                return resource.type === EntityConfigResourceType.DISPATCH_PLAYBOOKS
                  && ref?.key === resource.key;
              })?.resource.display_name}
            </li>
            <li>
              Stop Cascade:
              {' '}
              {`${rule.stop_cascade}`}
            </li>
          </ul>
        )}
        placeholder="No Permissions here yet!"
      >
        <NCSelect
          label="Condition"
          name="rule-condition"
          className="nc-l-mt_200_mobile"
          onChange={(event) => setNewDispatchPolicyRule((state) => ({
            ...state,
            conditionId: event.target.id,
          }))}
          value={conditionRef?.key ?? ''}
          options={resources
            .filter(
              ({ resource }) => resource.type === EntityConfigResourceType.DISPATCH_CONDITIONS,
            )
            .map(({ resource, id }) => ({
              label: resource.display_name,
              value: id,
            }))}
        />
        <NCSelect
          label="Playbook"
          name="rule-playbook"
          className="nc-l-mt_200_mobile"
          onChange={(event) => setNewDispatchPolicyRule((state) => ({
            ...state,
            playbookId: event.target.id,
          }))}
          value={playbookRef?.key ?? ''}
          options={resources
            .filter(
              ({ resource }) => resource.type === EntityConfigResourceType.DISPATCH_PLAYBOOKS,
            )
            .map(({ resource, id }) => ({
              label: resource.display_name,
              value: id,
            }))}
        />
        <NCInputCheckbox
          className="nc-l-mt_200_mobile"
          label="Stop cascade?"
          name="rule-stop_cascade"
          checked={newDispatchPolicyRule.stop_cascade}
          onChange={() => setNewDispatchPolicyRule((state) => ({
            ...state,
            stop_cascade: !newDispatchPolicyRule.stop_cascade,
          }))}
        />
      </InputList>
    </>
  );
}
