import { NCSelect } from '@daupler/nexus-components';
import { DispatchActionProps } from './resource-form-types';
import { EntityConfigResourceDispatchActionDispatchActionEscalateParams, EntityConfigResourceType } from '../../types/EntityConfig';

export function DispatchActionFormActionEscalate({
  onChange,
  value,
  resources,
}: DispatchActionProps) {
  const {
    _tree_id_ref: treeIdRef,
  } = value as EntityConfigResourceDispatchActionDispatchActionEscalateParams;
  return (
    <NCSelect
      label="Escalation Tree"
      name="close_action-tree"
      options={[
        { value: '', label: 'Choose one...' },
        ...resources
          .filter(({ resource }) => resource.type === EntityConfigResourceType.ESCALATION_TREES)
          .map(({ id, resource }) => ({
            label: resource.display_name,
            value: id,
          })),
      ]}
      value={treeIdRef?.key ?? ''}
      onChange={(event) => onChange({
        ...value as EntityConfigResourceDispatchActionDispatchActionEscalateParams,
        _tree_id_ref: {
          key: event.target.value,
          type: EntityConfigResourceType.ESCALATION_TREES,
        },
      })}
    />
  );
}
