import { FormField, NCSelect, useForm } from '@daupler/nexus-components';
import { CciDispatchRuleProps, ResourceFormProps } from './resource-form-types';
import { ResourceBaseForm } from './ResourceBaseForm';
import { getBaseResourceFormFields } from '../../utils/resource-forms';
import { useSyncResourceForm } from '../../hooks/useSyncResourceForm';
import { CciDispatchRuleType, EntityConfigResourceCciDispatchRule, EntityConfigResourceCciDispatchRuleParams } from '../../types/EntityConfig';
import { CciDispatchRuleFormAlwaysDispatch } from './CciDispatchRuleFormAlwaysDispatch';
import { CciDispatchRuleFormNeverDispatch } from './CciDispatchRuleFormCciNeverDispatch';
import { CciDispatchRuleFormNeverRedirect } from './CciDispatchRuleFormCciRedirect';
import { CciDispatchRuleFormCciSimplePrompt } from './CciDispatchRuleFormCciSimplePrompt';
import { CciDispatchRuleFormStandardRule } from './CciDispatchRuleFormStandardRule';

const RuleMap: Record<CciDispatchRuleType, (props: CciDispatchRuleProps) => React.JSX.Element> = {
  [CciDispatchRuleType.ALWAYS_DISPATCH]: CciDispatchRuleFormAlwaysDispatch,
  [CciDispatchRuleType.NEVER_DISPATCH]: CciDispatchRuleFormNeverDispatch,
  [CciDispatchRuleType.REDIRECT]: CciDispatchRuleFormNeverRedirect,
  [CciDispatchRuleType.SIMPLE_PROMPT]: CciDispatchRuleFormCciSimplePrompt,
  [CciDispatchRuleType.STANDARD_RULE]: CciDispatchRuleFormStandardRule,
};

export function CciDispatchRuleForm({
  onChange,
  onValidate,
  params,
  resourceData,
  resources,
  workgroups,
}: ResourceFormProps) {
  const {
    data: { type: ruleType, params: ruleParamsValue },
  } = resourceData as EntityConfigResourceCciDispatchRule ?? { data: {} };
  const type: FormField<CciDispatchRuleType | ''> = {
    invalidMessage: '',
    name: 'type',
    validate: () => true,
    validMessage: '',
    value: ruleType ?? '',
  };
  const ruleParams: FormField<EntityConfigResourceCciDispatchRuleParams | null> = {
    invalidMessage: '',
    name: 'ruleParams',
    validate: () => true,
    validMessage: '',
    value: ruleParamsValue ?? null,
  };
  const {
    formState,
    onChange: onFormChange,
    isFormValid,
  } = useForm({
    ...getBaseResourceFormFields({ resourceData }),
    type,
    ruleParams,
  });

  useSyncResourceForm({
    isFormValid,
    onChange,
    onValidate,
    resourceData: {
      display_name: formState.displayName.value,
      key: formState.key.value,
      _workgroup_ref: formState.workgroupRef.value ?? undefined,
      data: {
        type: formState.type.value,
        params: formState.ruleParams.value,
      },
    },
  });

  const RuleComponent = RuleMap[formState.type.value as CciDispatchRuleType] ?? null;

  return (
    <>
      <ResourceBaseForm
        formState={formState}
        onChange={onFormChange}
        workgroups={workgroups}
      />
      <NCSelect
        label="Type"
        className="nc-l-mt_200_mobile"
        name={formState.type.name}
        options={[
          { label: 'Choose one...', value: '' },
          ...Object.values(CciDispatchRuleType)
            .map((value) => ({
              label: value,
              value,
            })),
        ]}
        onChange={(event) => onFormChange(formState.type.name, event.target.value)}
        value={formState.type.value}
      />

      {formState.type.value && (
        <RuleComponent
          onChange={(value) => {
            onFormChange(
              formState.ruleParams.name,
              value,
            );
          }}
          params={params}
          resources={resources}
          value={formState.ruleParams.value}
        />
      )}
    </>
  );
}
