import { FormField, NCInputCheckbox, useForm } from '@daupler/nexus-components';
import { ResourceFormProps } from './resource-form-types';
import { useSyncResourceForm } from '../../hooks/useSyncResourceForm';
import { ResourceBaseForm } from './ResourceBaseForm';
import { getBaseResourceFormFields } from '../../utils/resource-forms';
import { EntityConfigResourceShift } from '../../types/EntityConfig';

export function ShiftForm({
  onChange,
  onValidate,
  resourceData,
  workgroups,
}: ResourceFormProps) {
  const {
    data: {
      is_after_hours: isAfterHoursValue,
    },
  } = resourceData as EntityConfigResourceShift ?? { data: {} };
  const isAfterHours: FormField<boolean> = {
    name: 'isAfterHours',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: isAfterHoursValue ?? false,
  };
  const {
    formState,
    onChange: onFormChange,
    isFormValid,
  } = useForm({
    ...getBaseResourceFormFields({ resourceData }),
    isAfterHours,
  });

  useSyncResourceForm({
    isFormValid,
    onChange,
    onValidate,
    resourceData: {
      display_name: formState.displayName.value,
      key: formState.key.value,
      _workgroup_ref: formState.workgroupRef.value,
      data: {
        is_after_hours: formState.isAfterHours.value,
      },
    },
  });

  return (
    <>
      <ResourceBaseForm
        formState={formState}
        onChange={onFormChange}
        workgroups={workgroups}
      />
      <NCInputCheckbox
        className="nc-l-mt_200_mobile"
        label="Is After Hours?"
        name={formState.isAfterHours.name}
        checked={formState.isAfterHours.value}
        onChange={() => onFormChange(
          formState.isAfterHours.name,
          !formState.isAfterHours.value,
        )}
      />
    </>
  );
}
