import {
  FormField,
  NCInputCheckbox,
  NCSelect,
  useForm,
} from '@daupler/nexus-components';
import { ResourceFormProps } from './resource-form-types';
import { useSyncResourceForm } from '../../hooks/useSyncResourceForm';
import { ResourceBaseForm } from './ResourceBaseForm';
import { getBaseResourceFormFields } from '../../utils/resource-forms';
import {
  AiTag,
  EntityConfigRef,
  EntityConfigResourceServiceCategory,
  EntityConfigResourceType,
} from '../../types/EntityConfig';

export function ServiceCategoryForm({
  onChange,
  onValidate,
  resourceData,
  resources,
  workgroups,
}: ResourceFormProps) {
  const {
    data: {
      _cci_dispatch_rule_ref: cciDispatchRuleRefValue,
      _dispatch_policy_ref: dispatchPolicyRefVaule,
      _incident_version_ref: incidentVersionRefValue,
      _service_area_ref: serviceAreaRefValue,
      _service_lists_ref_list: serviceListRefsValue,
    },
  } = resourceData as EntityConfigResourceServiceCategory ?? { data: {} };
  const dispatchPolicyRef: FormField<EntityConfigRef | undefined> = {
    name: 'dispatchPolicyRef',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: dispatchPolicyRefVaule,
  };
  const incidentVersionRef: FormField<EntityConfigRef | undefined> = {
    name: 'incidentVersionRef',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: incidentVersionRefValue,
  };
  const serviceAreaRef: FormField<EntityConfigRef | undefined> = {
    name: 'serviceAreaRef',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: serviceAreaRefValue,
  };
  const serviceListRefs: FormField<EntityConfigRef[]> = {
    name: 'serviceListRefs',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: serviceListRefsValue,
  };
  const cciDispatchRuleRef: FormField<EntityConfigRef | undefined> = {
    name: 'cciDispatchRuleRef',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: cciDispatchRuleRefValue,
  };
  const isPolicyFallbackCategory: FormField<boolean> = {
    name: 'isPolicyFallbackCategory',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: false,
  };
  const scenarios: FormField<[string, string][]> = {
    name: 'scenarios',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: [],
  };
  const simpleAiTags: FormField<AiTag[]> = {
    name: 'isPolicyFallbackCategory',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: [],
  };
  const {
    formState,
    onChange: onFormChange,
    isFormValid,
  } = useForm({
    ...getBaseResourceFormFields({ resourceData }),
    incidentVersionRef,
    dispatchPolicyRef,
    serviceAreaRef,
    serviceListRefs,
    cciDispatchRuleRef,
    isPolicyFallbackCategory,
    scenarios,
    simpleAiTags,
  });

  useSyncResourceForm({
    isFormValid,
    onChange,
    onValidate,
    resourceData: {
      display_name: formState.displayName.value,
      key: formState.key.value,
      _workgroup_ref: formState.workgroupRef.value,
      data: {
        _dispatch_policy_ref: formState.dispatchPolicyRef.value,
        _incident_version_ref: formState.incidentVersionRef.value,
        _service_area_ref: formState.serviceAreaRef.value,
        _service_lists_ref_list: formState.serviceListRefs.value,
        _cci_dispatch_rule_ref: formState.cciDispatchRuleRef.value,
        is_policy_fallback_category: formState.isPolicyFallbackCategory.value,
        scenarios: {
          v2: formState.scenarios.value,
        },
        simple_ai_tags: formState.simpleAiTags.value,
      },
    },
  });

  return (
    <>
      <ResourceBaseForm
        formState={formState}
        onChange={onFormChange}
        workgroups={workgroups}
      />
      <NCSelect
        label="Dispatch Policy"
        className="nc-l-mt_200_mobile"
        name={formState.dispatchPolicyRef.name}
        options={[
          { label: 'Choose one...', value: '' },
          ...resources
            .filter(({ resource }) => resource.type === EntityConfigResourceType.DISPATCH_POLICIES)
            .map(({ resource, id }) => ({
              label: resource.display_name,
              value: id,
            })),
        ]}
        onChange={(event) => onFormChange(formState.dispatchPolicyRef.name, event.target.value)}
        value={formState.dispatchPolicyRef.value?.key ?? ''}
      />
      <NCSelect
        label="Incident Version"
        className="nc-l-mt_200_mobile"
        name={formState.incidentVersionRef.name}
        options={[
          { label: 'Choose one...', value: '' },
          ...resources
            .filter(({ resource }) => resource.type === EntityConfigResourceType.INCIDENT_VERSIONS)
            .map(({ resource, id }) => ({
              label: resource.display_name,
              value: id,
            })),
        ]}
        onChange={(event) => onFormChange(formState.incidentVersionRef.name, event.target.value)}
        value={formState.incidentVersionRef.value?.key ?? ''}
      />
      <NCSelect
        label="Service Area"
        className="nc-l-mt_200_mobile"
        name={formState.serviceAreaRef.name}
        options={[
          { label: 'Choose one...', value: '' },
          ...resources
            .filter(({ resource }) => resource.type === EntityConfigResourceType.SERVICE_AREAS)
            .map(({ resource, id }) => ({
              label: resource.display_name,
              value: id,
            })),
        ]}
        onChange={(event) => onFormChange(formState.serviceAreaRef.name, event.target.value)}
        value={formState.serviceAreaRef.value?.key ?? ''}
      />
      <NCSelect
        label="Service Lists"
        className="nc-l-mt_200_mobile"
        name={formState.serviceListRefs.name}
        multiple
        options={[
          ...resources
            .filter(({ resource }) => resource.type === EntityConfigResourceType.SERVICE_LISTS)
            .map(({ resource, id }) => ({
              label: resource.display_name,
              value: id,
            })),
        ]}
        onChange={(event) => onFormChange(
          formState.serviceListRefs.name,
          Array.from(event.target.options)
            .filter((option) => option.selected)
            .map((option) => ({
              key: option.value,
              type: EntityConfigResourceType.SERVICE_LISTS,
            })),
        )}
        value={formState.serviceListRefs.value.map(({ key }) => key)}
      />
      <NCSelect
        label="CCI Dispatch Rule"
        className="nc-l-mt_200_mobile"
        name={formState.cciDispatchRuleRef.name}
        options={[
          { label: 'Choose one...', value: '' },
          ...resources
            .filter(({ resource }) => resource.type === EntityConfigResourceType.CCI_DISPATCH_RULES)
            .map(({ resource, id }) => ({
              label: resource.display_name,
              value: id,
            })),
        ]}
        onChange={(event) => onFormChange(formState.cciDispatchRuleRef.name, event.target.value)}
        value={formState.cciDispatchRuleRef.value?.key ?? ''}
      />
      <NCInputCheckbox
        label="Is Policy Fallback Category?"
        name={formState.isPolicyFallbackCategory.name}
        checked={formState.isPolicyFallbackCategory.value}
        onChange={() => onFormChange(
          formState.isPolicyFallbackCategory.name,
          !formState.isPolicyFallbackCategory.value,
        )}
      />
      <p>SCENARIOS: TBD</p>
      <NCSelect
        label="Simple AI Tags"
        className="nc-l-mt_200_mobile"
        name={formState.simpleAiTags.name}
        options={Object.values(AiTag).map((aiTag) => ({
          label: aiTag,
          value: aiTag,
        }))}
        onChange={(event) => onFormChange(formState.cciDispatchRuleRef.name, event.target.value)}
        value={formState.cciDispatchRuleRef.value?.key ?? ''}
      />
    </>
  );
}
